import React from "react"
import LayoutProductions from "../../components/layout/layout-productions"
import TitledContentWrapper from "../../components/layout/titled-content-wrapper"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Button } from "@material-ui/core"
import { DigicLink } from "../../components/digic-link"

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: "20px",
    marginTop: "40px",
    textShadow: "0px 15px 30px #000000",
    text: "uppercase",
    fontWeight: "700",
  },
  content: {
    marginBottom: "20px",
  },
  download: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "black",
    fontSize: "16px",
    fontWeight: "700",
    border: "solid 3px #F26522",
    background: "#F26522",
    width: "200px",
    paddingTop: "11px",
    paddingBottom: "11px",
    cursor: "pointer",
    marginLeft: "0px",
    marginRight: "20px",
    marginTop: "25px",
    marginBottom: "25px",
    textAlign: "center",
  },
}))

const SubmissionPage = () => {
  const classes = useStyles()
  return (
    <LayoutProductions>
      <TitledContentWrapper title="submission success">
        <Typography variant="h2" className={classes.title}>
          Congratulations! Your submission was successful.
        </Typography>
        <DigicLink to="/productions">
          <Button
            variant="contained"
            style={{ marginTop: "16px", marginBottom: "40px" }}
          >
            Back to productions
          </Button>
        </DigicLink>
      </TitledContentWrapper>
    </LayoutProductions>
  )
}

export default SubmissionPage
